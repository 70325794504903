import throttle from "lodash/throttle";

const INTERCOM_SETTINGS = {
  app_id: "xziah0z7",
  alignment: "right",
  custom_launcher_selector: ".launch-intercom",
  vertical_padding: 90
};

const configureIntercom = action => {
  if (window.Intercom) {
    window.Intercom(action, {
      ...INTERCOM_SETTINGS,
      hide_default_launcher: true
    });
  }
};

configureIntercom("boot");

window.addEventListener(
  "resize",
  throttle(() => configureIntercom("update"), 200)
);
